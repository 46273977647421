<template>
  <div>
     <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click=" $router.push('/Patient')"
    />
    <vs-alert color="danger" title="Course Not Found" :active.sync="patient_not_found">
      <span>patient record with id: {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'Patient' }" class="text-inherit underline"
          >All patient</router-link
        >
      </span>
    </vs-alert>
    <div v-if="!patient_not_found || (this.$route.params.ID && this.patientModel.ID)">
      <patient-Tab class="mt-4" :mainTabName="'profile'" />
      <vx-card>
        <div slot="no-body" class="tabs-container px-6 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <patient-tab-main class="mt-4" :patientModel="patientModel" />
              </div>
            </vs-tab>

            <!-- <vs-tab
              :label="$t('History Scan')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <patient-tab-history-scan class="mt-4" :patientModel="patientModel" />
              </div>
            </vs-tab> -->
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="mr-6"
              @click="submitData"
              :disabled="!isFormValid"
              v-if="CanWrite"
              >{{ $t("Save") }}</vs-button
            >
            <vs-button type="border" color="danger" :to="{ name: 'User' }">{{
              $t("Cancel")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PatientTabMain from "./PatientTabMain.vue";
// import PatientTabEducation from "./PatientTabEducation.vue";
// import PatientTabHistoryScan from "./PatientTabHistoryScan.vue";

import modulePatient from "@/store/Patient/modulePatient.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import patientTab from "@/views/tabs/patientTab.vue";

export default {
  components: {
    PatientTabMain,
    patientTab,
    // PatientTabEducation,
    // PatientTabHistoryScan,
  },
  data() {
    return {
      patient_not_found: false,
      activeTab: 0,
      CanWrite: false,
      patientModel: {
        KinshipUsers: [],
      },
    };
  },
  computed: {
    Currencies() {
      return this.$store.state.CurrencyList.Currencies;
    },

    isFormValid() {
      return !this.errors.any() && this.patientModel.NameEN;
    },
  },
  methods: {
    submitData() {
      if (this.patientModel.ID !== null && this.patientModel.ID >= 0) {
        this.$vs.loading();
        this.$store
          .dispatch("patientList/UpdatePatient", this.patientModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess(res.data.message);
            this.$router.push({ name: "Patient" });

            this.initPatientModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError(err.response.data.message);
          });
      } else {
        delete this.patientModel.ID;
        this.patientModel.popularity = 0;
        this.patientModel.UserType = "Patient";
        this.$vs.loading();
        this.$store
          .dispatch("patientList/AddPatient", this.patientModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess(res.data.message);
            this.$router.push({ name: "Patient" });
            //  this.$store.state.courseList.search.pageNum=1;
            this.initPatientModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError(err.response.data.message);
          });
      }
    },
    initValues() {
      this.patientModel = {};
      this.patientModel.KinshipUsers = [];
    },
    initPatientModelValues() {
      this.patientModel = {};
      this.patientModel.KinshipUsers = [];
    },
  },
  created() {
    this.CanWrite =
      JSON.parse(localStorage.getItem("userInfo")).CanWrite ||
      JSON.parse(localStorage.getItem("userInfo")).userRole == "admin" ||
      JSON.parse(localStorage.getItem("userInfo")).userRole == "AgentsSupervisor";
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }
    this.initPatientModelValues();
    this.initValues();
    //  this.patientModel.test = "";
    this.$store.dispatch("CurrencyList/GetAllCurrencies");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // var vm = this;
    const ID = this.$route.params.ID;
    debugger
    if (ID != undefined) {
      this.$store.dispatch("patientList/GetPatient", ID).then((res) => {
        debugger
        this.patientModel = res.data.Data;
        this.$store.commit("SET_SetTitle", "" + this.patientModel.NameEN);
        debugger
      });
    }
  },
};
</script>
